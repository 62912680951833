.product_item {
  height: calc(100vh - 156px);
}

.product_item .create_product {
  height: calc(100% - 20px);
  overflow-y: auto;
}

.product_item .product_list {
  padding-bottom: 20px;
  height: calc(100% - 50px);
  overflow-y: auto;
}

.product_item .product_lists {
  height: calc(100% - 50px);
}

.final_product .product_list {
  padding-bottom: 20px;
  height: calc(100% - 58px);
  overflow-y: auto;
}

.product_item ::-webkit-scrollbar {
  display: block;
  width: 0;
}

.inclusive_radio .MuiFormControlLabel-label {
  color: #ADADAD;
}

.inclusive_radio .MuiRadio-root .MuiSvgIcon-root {
  width: 18px;
  height: 18px;
}

.add_branch .formContainer {
  padding: 0 !important;
}

.formContainer .MuiInput-underline::before,
.formContainer input {
  border-color: #EBEAED;
}

.planCard {
  width: 140px;
  border-radius: 6px;
  padding: 5px;
  background: #ffffff;
  mix-blend-mode: normal;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.08);
  border-radius: 6px;

  .planCardChild {
    border: 2px solid #e1e1e1;
    border-radius: 6px;
    padding: 5px;
  }
}


::-webkit-scrollbar {
  width: 4px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--lightestgrey);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #666;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}